<script>
import {reactive, toRefs} from 'vue';
import {  ElMessageBox } from 'element-plus';
import router from "../router";

export default {
  name: 'AllReset',
  setup() {
    const data = reactive({
      count: 0,
    });

    function openConfirm() {
      ElMessageBox.confirm(
          'Opravdu chcete vymazat všechna data? Tato akce je nevratná.',
          'Varování',
          {
            cancelButtonText: 'Ne',
            confirmButtonText: 'Ano',
            type: 'danger',
          }
      )
          .then(() => {
            helpers.server.fetchPost(router.xhr.default, {action: 'resetAll'});
          });
    }

    return {
      ...toRefs(data), openConfirm
    }
  }
}
</script>

<template>
  <button class="btn btn-outline-warning" @click="openConfirm"><em class="fa-light fa-backward-fast"></em></button>
</template>

<style scoped>

</style>