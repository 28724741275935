<script>
import {onMounted, reactive, toRefs} from 'vue';

export default {
  name: 'RoundViewer',
  setup() {
    const data = reactive({
      rounds: [],
    });

    onMounted(() => {
      window.eventBus.on('ReceivedRoundItem', (item) => {
        //pridej data do pole pokud tam jeste nejsou nebo jen zaktualizuj
        let index = data.rounds.findIndex((round) => round.id === item.id);
        if (index === -1) {
          data.rounds.unshift(item);
        } else {
          data.rounds[index] = item;
        }
      });
    });

    return {
      ...toRefs(data),
    }
  }
}
</script>

<template>
  <div>
    <p class="fw-bold">Poslední kola</p>
    <div class="d-flex flex-column gap-3">
      <div v-for="round in rounds" :key="round.id" class="d-flex flex-column">
        <span>Uživatel: <strong>{{ round.user.name }}</strong></span>
        <span>Počet kol: <strong>{{ round.user.roundsCount }}</strong></span>
        <span>Aktuálního kolo: <strong>{{ round.state }}</strong></span>
        <span v-if="round.lastCheckpoint">Checkpoint: <strong>{{ round.lastCheckpoint.name }}</strong></span>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>