// CSS
import 'bootstrap/scss/bootstrap.scss';
// import 'animate.css/animate.css';

//Fontawesome PRO
import './Css/fontawesome/scss/fontawesome.scss';
import './Css/fontawesome/scss/light.scss';
import './Css/fontawesome/scss/solid.scss';
import './Css/fontawesome/scss/regular.scss';

//JS
import * as bootstrap from 'bootstrap';

// VUE
import { createApp } from 'vue/dist/vue.esm-bundler';
import {reactive, toRefs} from "vue";

//Element-plus
import ElementPlus from 'element-plus';
import elementCS from 'element-plus/es/locale/lang/cs'
import 'element-plus/dist/index.css';

// mitt
import mitt from 'mitt';
window.eventBus = mitt();

import helpers from './helpers';
window.helpers = helpers;

// COMPONENTS
import Test from "./Components/Test/Test.vue";
import ReceivedViewer from "./Components/ReceivedViewer/ReceivedViewer.vue";
import LeafletMap from "./Components/Map/LeafletMap.vue";
import DataSwitcher from "./Components/Data/DataSwitcher.vue";
import RoundViewer from "./Components/Round/RoundViewer.vue";
import Leaderboard from "./Components/Board/Leaderboard.vue";
import AllReset from "./Components/AllReset.vue";

// VUE INIT AND MOUNT
export const app = createApp({
	setup() {
		const data = reactive({
		});

		return {
			...toRefs(data)
		}
	}
})
	.component(Test.name, Test)
	.component(ReceivedViewer.name, ReceivedViewer)
	.component(LeafletMap.name, LeafletMap)
	.component(DataSwitcher.name, DataSwitcher)
	.component(RoundViewer.name, RoundViewer)
	.component(Leaderboard.name, Leaderboard)
	.component(AllReset.name, AllReset)
	.use(ElementPlus, { locale: elementCS })
	.mount('#app');