<script>
import {onMounted, reactive, toRefs} from 'vue';

export default {
  name: 'Leaderboard',
  setup() {
    const data = reactive({
      state: 'info',
      stateText: 'Disconnected',
      userRoundsStats: []
    });

    onMounted(() => {
      window.eventBus.on('ReceivedState', (received) => {
        data.state = received.state;
        data.stateText = received.text;
      });
      window.eventBus.on('ReceivedUserRoundsStats', (userRoundsStats) => {
        data.userRoundsStats = userRoundsStats;
      });
    });

    function secondsToString(seconds) {
      if (seconds === null || seconds === undefined || seconds === 0) {
        return '--:--';
      }

      const date = new Date(null);
      date.setSeconds(seconds);
      return date.toLocaleTimeString(undefined, {minute: '2-digit', second: '2-digit'});
    }

    return {
      ...toRefs(data), secondsToString
    }
  }
}
</script>

<template>

  <table class="leader-table">
    <thead>
    <tr>
      <td colspan="3">
        <div class="d-flex gap-3 px-2 py-2">
          <em class="fa-regular fa-wifi"></em>
          <el-tag :type="state" effect="dark" class="flex-fill">
            {{ stateText }}
          </el-tag>
        </div>
      </td>
    </tr>
    </thead>

    <transition-group tag="tbody" name="table-row" class="table-body">
      <tr v-for="userRoundsStat in userRoundsStats" :key="userRoundsStat.userId">
        <td>
          <span :style="'background-color: '+userRoundsStat.userColor+'; color: '+userRoundsStat.userColor+';'">#</span>
          {{ userRoundsStat.userName }}
        </td>
        <td>
          <em class="fa-solid fa-fw fa-flag-checkered"></em>
          {{ userRoundsStat.completedRounds }}
        </td>
        <td>
          <em class="fa-regular fa-fw fa-star"></em>
          {{ secondsToString(userRoundsStat.fastestRoundTimeSec) }}
        </td>
      </tr>
    </transition-group>
  </table>


</template>

<style scoped>
.table-body tr {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.table-row-enter-active, .table-row-leave-active {
  transition: all 0.5s ease;
}

.table-row-enter-from, .table-row-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.leader-table {
  font-size: 1.75rem;
  list-style: none;
  padding: 0;
  margin: 0;
}


.leader-table tr {
  background-color: white;
  border-bottom: 1px solid grey;
}
.leader-table td {
  padding: 3px 10px;
}

.leader-list li[first-child] {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
</style>