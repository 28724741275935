<script>
import "leaflet/dist/leaflet.css";
import {LMap, LTileLayer, LCircle, LPolyline, LMarker, LGeoJson, LControl} from "@vue-leaflet/vue-leaflet";
import "leaflet-spline";
import L from "leaflet";
import "leaflet.fullscreen";
import {debounce} from "lodash";

export default {
  name: 'LeafletMap',
  components: {
    LMap,
    LTileLayer,
    LCircle,
    LPolyline,
    LMarker,
    LGeoJson,
    LControl
  },
  props: {
    checkpoints: {
      type: Array,
      required: false,
      default: () => []
    },
    mapCenter: {
      type: Array,
      required: false,
      default: () => [50.6595989, 14.5390750]
    },
    fitBounds: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      liveData: true,
      slidersValue: [],
      zoom: 19,
      map: null,
      userLatLngs: [],
      maxCircles: 5,
      showPolyline: false,
      showCircles: true,
      polylineWeight: 3
    }
  },
  async mounted() {
    window.eventBus.on('liveData', (value) => {
      this.liveData = value;
    });

    window.eventBus.on('clearMap', _ => {
      //vyprazdnit userLatLngs
      this.userLatLngs = [];
      this.slidersValue = [];
    });
  },
  methods: {
    changeWeight(newWeight) {
      console.log(this.$refs.geojson[0].leafletObject.setStyle({
        weight: newWeight,
        color: '#00ff3d'
      }));
    },
    addUserLatLngs(userId, userColor, userName) {
      let userLatLng = {
        user: {
          id: userId,
          color: userColor,
          name: userName
        },
        // polyline: L.polyline([], {
        //   color: userColor,
        //   smoothFactor: 1
        // }).addTo(this.map),
        items: [],
        sortedLatLngs: []
      };
      this.userLatLngs.push(userLatLng);
      return userLatLng;
    },
    mapReady() {
      this.map = this.$refs.map.leafletObject;
      window.eventBus.emit('MapReady', this.map);

      // let OffsetTileLayer = L.TileLayer.extend({
      //   _getTilePos: function (coords) {
      //     let pos = L.TileLayer.prototype._getTilePos.call(this, coords);
      //     return pos.add([-40, -3]);
      //   },
      // });
      // new OffsetTileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   attribution: '©D5T5',
      //   opacity: 1
      // }).addTo(this.map);
      //

      let offsetTileLayerWms = L.TileLayer.WMS.extend({
        // _getTilePos: function (coords) {
        //   let pos = L.TileLayer.WMS.prototype._getTilePos.call(this, coords);
        //   return pos.add([-40, -3]);
        // },
      });

      new offsetTileLayerWms('https://geoportal.cuzk.cz/WMS_ORTOFOTO_PUB/service.svc/get?', {
        layers: 'GR_ORTFOTORGB',
      }).addTo(this.map);

      //FULLSCREEN
      L.control.fullscreen({ position: 'topright' }).addTo(this.map);

      window.eventBus.on('ReceivedGpsItem', (data) => {
        let userLatLng = this.userLatLngs.find((userLatLngs) => {
          return userLatLngs.user.id === data.user.id;
        });

        if (userLatLng === undefined) {
          userLatLng = this.addUserLatLngs(data.user.id, data.user.color, data.user.name, []);
        }

        userLatLng.items.push({
          unixtime: data.unixtime,
          latLng: [data.lat, data.lon],
          color: (data.checkpoint === null && data.lineCheckpoint === null ? data.user.color : '#44c12f')
        });

        // pokud ve slidersValue existuje klic data.user.id a jsou liveData tak druha hodnota na aktualni pocet items
        // if (this.slidersValue[data.user.id] !== undefined && this.liveData) {
        //   this.slidersValue[data.user.id][1] = userLatLng.items.length;
        // }

        //srovnano podle unixtime a vytazeny pouze latLng a ulozeno do sortedLatLngs
        userLatLng.sortedLatLngs = userLatLng.items.sort((a, b) => {
          return a.unixtime - b.unixtime;
        }).map((item) => {
          return {
            color: item.color,
            latLng: item.latLng
          }
        });

        if (this.maxCircles > 0) {
          // pokud sortedLatLngs ma vic nez this.maxCircles prvky, ostatni smazeme
          if (userLatLng.items.length > this.maxCircles) {
            userLatLng.items = userLatLng.items.slice(userLatLng.items.length - this.maxCircles);
          }

          if (userLatLng.sortedLatLngs.length > this.maxCircles) {
            userLatLng.sortedLatLngs = userLatLng.sortedLatLngs.slice(userLatLng.sortedLatLngs.length - this.maxCircles);
          }

        }

        // userLatLng.polyline.setLatLngs(userLatLng.sortedLatLngs);

        if (this.fitBounds) {
          this.map.fitBounds(userLatLng.sortedLatLngs.map(item => item.latLng));
        }
      });

    },
    setSliders: debounce(function (value, id) {
      this.slidersValue[id] = value;
    }, 25)
  },
  computed: {
    userLatLngsSlided() {
      if (this.slidersValue.length === 0) {
        return this.userLatLngs;
      }

      return this.userLatLngs.map((userLatLng) => {
        let sortedLatLngs = userLatLng.sortedLatLngs.slice(this.slidersValue[userLatLng.user.id][0], this.slidersValue[userLatLng.user.id][1]);
        return {
          user: userLatLng.user,
          sortedLatLngs: sortedLatLngs
        }
      });
    }
  }
}
</script>

<template>
  <div>
    <div style="height: 100vh; width:100vw; max-width: 100%;" class="mb-5 p-0">
      <l-map @ready="mapReady"
             :useGlobalLeaflet="false"
             ref="map"
             v-model:zoom="zoom"
             :center="mapCenter">

        <l-control position="topleft">
          <slot name="topleft-slot"></slot>
        </l-control>

        <l-control position="bottomright">
          <slot name="bottomright-slot"></slot>
        </l-control>

        <l-control position="bottomleft">
          <div class="card">
            <div class="card-body d-flex flex-column gap-1 p-2">
              <el-checkbox size="small" v-model="showCircles">Circles</el-checkbox>
              <el-input-number size="small" :min="0" :max="100" v-model="maxCircles" label="Max circles"/>
              <el-checkbox size="small" v-model="showPolyline">Polyline</el-checkbox>
              <el-input-number size="small" :min="3" :max="20" v-model="polylineWeight" label="Polyline weight"/>
            </div>
          </div>
        </l-control>

        <l-geo-json v-for="(checkpoint, idx) in checkpoints"
                    ref="geojson"
                    :key="idx"
                    :geojson="JSON.parse(checkpoint)"/>

        <div v-for="(userLatLng, idxx) in userLatLngsSlided" :key="userLatLng.user.id">
          <l-polyline v-if="showPolyline" :smooth-factor="1"
                      :lat-lngs="userLatLng.sortedLatLngs.map(item => item.latLng)"
                      :key="idxx"
                      :opacity="1"
                      :fill-opacity="1"
                      :weight="polylineWeight"
                      :color="userLatLng.user.color"/>

          <l-circle v-if="showCircles" v-for="(item, idx) in userLatLng.sortedLatLngs"
                    :key="idx"
                    :lat-lng="item.latLng"
                    :radius="1"
                    :fill-color="item.color"
                    :opacity="idx === 0 ? 0.5 : (idx === 1 ? 0.75 : 1)"
                    :fill-opacity="idx === 0 ? 0.5 : (idx === 1 ? 0.75 : 1)"
                    :color="item.color"/>
        </div>
      </l-map>
    </div>

    <el-slider
        v-if="liveData === false"
        v-for="userLatLng in userLatLngs"
        :key="userLatLng.user.id"
        @input="setSliders($event, userLatLng.user.id)"
        range
        :step="1"
        :min="0"
        :max="userLatLng.items.length"
    ></el-slider>
  </div>
</template>

<style scoped>
@import '~leaflet.fullscreen/Control.FullScreen.css';

.status-text {
  background-color: white;
  color: black;
  padding-left: 1em;
  padding-right: 1em;
}

.status-shadow {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}
</style>